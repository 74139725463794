import { Controller } from "stimulus"
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

export default class extends Controller {
    static targets = ['selectedFilter', 'priceSlider']
    static values = {
        min: Number,
        max: Number,
        minSelected: Number,
        maxSelected: Number
    }

    connect() {
        if (this.hasPriceSliderTarget) {
            const minPrice = this.minValue
            let maxPrice = this.maxValue
            if (minPrice === maxPrice) {
                maxPrice += 1
            }

            noUiSlider.create(this.priceSliderTarget,
                {
                    range: {
                        'min': minPrice,
                        'max': maxPrice
                    },
                    step: 50,
                    orientation: 'horizontal',
                    connect: true,
                    start: [minPrice, maxPrice],
                    tooltips: [wNumb({decimals: 0}), wNumb({decimals: 0})],
                    pips: {
                        mode: 'positions',
                        values: [0, 100],
                        density: 4
                    }
                })

            this.priceSliderTarget.noUiSlider.set([this.minSelectedValue, this.maxSelectedValue])
        }
    }

    filter(event) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        let selectedCheckboxes = this.selectedFilterTargets
        const origin = window.location.pathname
        const params = window.location.search

        let urlSearchParams = new URLSearchParams(params)
        urlSearchParams.delete('filters[]')
        selectedCheckboxes.forEach(checkbox => {
            if (checkbox.checked) {
                urlSearchParams.append('filters[]', checkbox.value)
            }
        })

        if (this.hasPriceSliderTarget) {
            urlSearchParams.delete('min')
            urlSearchParams.delete('max')

            let priceRange = this.priceSliderTarget.noUiSlider.get()
            let minPriceSelected = priceRange[0]
            let maxPriceSelected = priceRange[1]
            if (parseInt(minPriceSelected) !== this.minValue || parseInt(maxPriceSelected) !== this.maxValue) {
                urlSearchParams.append('min', minPriceSelected)
                urlSearchParams.append('max', maxPriceSelected)
            }
        }

        const urlSearchParamsString = decodeURI(urlSearchParams.toString())

        window.location.replace(origin + (urlSearchParamsString.length === 0 ? '' : `?${urlSearchParamsString}`))
    }

}