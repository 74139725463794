import { Controller } from "stimulus"
import SwiperCore from 'swiper/core';
import { Modal } from 'bootstrap'

const imagesLoaded = require('imagesloaded');

const show = (element) => element.classList.remove('d-none')
const hide = (element) => element.classList.add('d-none')

let swiper;

export default class extends Controller {
    static targets = ['spinner', 'largeImage', 'zoomModal', 'errorMessage', 'imagesSwiper']

    connect() {
        swiper = new SwiperCore(this.imagesSwiperTarget, {
            direction: 'horizontal',
            loop: false
        });
    }

    zoom(event) {
        hide(this.errorMessageTarget)
        hide(this.largeImageTarget)
        show(this.spinnerTarget)

        new Modal(this.zoomModalTarget).show()

        this.largeImageTarget.src = event.currentTarget.getAttribute('data-large-src')
        const imgLoad = imagesLoaded(this.largeImageTarget)
        imgLoad.on('done', () => {
            hide(this.spinnerTarget)
            show(this.largeImageTarget)
        })

        imgLoad.on('fail', () => {
            hide(this.spinnerTarget)
            show(this.errorMessageTarget)
        })
    }

    slide(event) {
        const slide = parseInt(event.currentTarget.getAttribute('data-slide'))
        swiper.slideTo(slide)
    }

}