import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("@rails/activestorage").start()

import 'bootstrap'
import { Tooltip } from "bootstrap";

document.addEventListener("DOMContentLoaded", function() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.forEach(trigger => new Tooltip(trigger))
});

// Stimulus
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))