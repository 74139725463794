import { Controller } from "stimulus"
import TomSelect from "tom-select/dist/esm/tom-select";
import "tom-select/dist/esm/plugins/dropdown_input/plugin"
import IMask from "imask";
import Stepper from "bs-stepper";

const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneNumberRegexp = /^\+38[0-9]{10}$/
const zipcodeRegexp = /^\d{5}$/

let stepper;

let phoneNumberMask;

let citySelect;
let addressSelect;

const i18nMessages = {
    'ru': {
        required: 'Обязательное поле',
        incorrectFormat: 'Неверный формат',
        zipcode: 'Обязательное поле. 5 цифр.',
        cash: 'Наличный расчет',
        later_cash: 'Наложенный платеж'
    },
    'uk': {
        required: 'Обов`язкове поле',
        incorrectFormat: 'Невірний формат',
        zipcode: 'Обов`язкове поле. 5 цифр.',
        cash: 'Готівковий розрахунок',
        later_cash: 'Накладений платіж'
    }
}

const getTomSelectRender = (fieldLabel, locale) => {
    if (locale === 'ru') {
        return {
            'option': function(data, escape) {
                return '<div>' + escape(data[fieldLabel]) + '</div>';
            },
            'item': function(data, escape) {
                return '<div>' + escape(data[fieldLabel]) + '</div>';
            },
            'option_create': function(data, escape) {
                return '<div class="create">Добавить <strong>' + escape(data.input) + '</strong>&hellip;</div>';
            },
            'no_results': function(data, escape) {
                return '<div class="no-results">Не найдено: "' + escape(data.input) + '"</div>';
            },
            'not_loading': function(data, escape) {
                // no default content
            },
            'optgroup': function(data) {
                let optgroup = document.createElement('div');
                optgroup.className = 'optgroup';
                optgroup.appendChild(data.options);
                return optgroup;
            },
            'optgroup_header': function(data, escape) {
                // return '<div class="optgroup-header">' + escape(data[field_optgroup]) + '</div>';
                return null;
            },
            'loading': function(data, escape) {
                return '<div class="spinner"></div>';
            },
            'dropdown': function() {
                return '<div></div>';
            }
        }
    } else {
        return {
            'option': function(data, escape) {
                return '<div>' + escape(data[fieldLabel]) + '</div>';
            },
            'item': function(data, escape) {
                return '<div>' + escape(data[fieldLabel]) + '</div>';
            },
            'option_create': function(data, escape) {
                return '<div class="create">Додати <strong>' + escape(data.input) + '</strong>&hellip;</div>';
            },
            'no_results': function(data, escape) {
                return '<div class="no-results">Не знайдено: "' + escape(data.input) + '"</div>';
            },
            'not_loading': function(data, escape) {
                // no default content
            },
            'optgroup': function(data) {
                let optgroup = document.createElement('div');
                optgroup.className = 'optgroup';
                optgroup.appendChild(data.options);
                return optgroup;
            },
            'optgroup_header': function(data, escape) {
                // return '<div class="optgroup-header">' + escape(data[field_optgroup]) + '</div>';
                return null;
            },
            'loading': function(data, escape) {
                return '<div class="spinner"></div>';
            },
            'dropdown': function() {
                return '<div></div>';
            }
        }
    }
}

const appendAfter = (element, after) => after.parentNode.insertBefore(element, after.nextSibling)

const appendError = (element, message) => {
    if (!element.classList.contains('is-invalid')) {
        element.classList.add('is-invalid')
        const newNode = document.createElement('div')
        newNode.className = 'invalid-feedback'
        newNode.innerText = message

        element.parentNode.insertBefore(newNode, element.nextSibling);
    }
}

const removeError = (element) => {
    if (element.classList.contains('is-invalid')) {
        element.classList.remove('is-invalid')
        element.parentElement.querySelector('.invalid-feedback').remove()
    }
}

export default class extends Controller {
    static targets = ['firstName', 'lastName', 'email', 'phoneNumber', 'shippingMethod', 'city', 'address',
        'zipcode', 'shippingMethodContainer', 'ukrPoshtaContainer', 'paymentMethod', 'stepper', 'novaPoshtaContainer',
        'novaPoshtaTemplate', 'meestExpressContainer', 'meestExpressTemplate', 'ukrPoshtaTemplate', 'summaryFullName',
        'summaryEmail', 'summaryPhoneNumber', 'summaryShippingMethod', 'summaryAddress', 'summaryPaymentMethod', 'submitButton'
    ]
    static values = {
        locale: String
    }

    connect() {
        // Init phone number mask
        const maskOptions = {
            mask: '{+38} (000) 000-00-00',
            lazy: false
        }
        phoneNumberMask = IMask(this.phoneNumberTarget, maskOptions)
        phoneNumberMask.on('accept', () => {
            if (phoneNumberRegexp.test(phoneNumberMask.unmaskedValue)) {
                removeError(this.phoneNumberTarget)
            }
        })

        // Init Stepper
        stepper = new Stepper(this.stepperTarget, {
            animation: true
        })

        this.showShippingFields()
    }

    showShippingFields() {
        const value = this.shippingMethodTarget.value

        if (this.hasNovaPoshtaContainerTarget) {
            this.novaPoshtaContainerTarget.remove()
        }
        if (this.hasUkrPoshtaContainerTarget) {
            this.ukrPoshtaContainerTarget.remove()
        }
        if (this.hasMeestExpressContainerTarget) {
            this.meestExpressContainerTarget.remove()
        }

        if (value === 'nova_poshta') {
            appendAfter(this.novaPoshtaTemplateTarget.content.cloneNode(true), this.shippingMethodContainerTarget)

            let locale = this.localeValue.charAt(0).toUpperCase() + this.localeValue.slice(1)
            addressSelect = new TomSelect(this.addressTarget, {
                plugins: ['dropdown_input'],
                valueField: 'value',
                labelField: 'label',
                searchField: 'label',
                render: getTomSelectRender('label', this.localeValue),
                onChange: () => {
                    this.requiredSelect2(addressSelect)
                }
            })
            addressSelect.disable()

            citySelect = new TomSelect(this.cityTarget, {
                plugins: ['dropdown_input'],
                valueField: 'DeliveryCity',
                labelField: 'Present',
                searchField: 'Present',
                render: getTomSelectRender('Present', this.localeValue),
                onChange: (value) => {
                    if (this.requiredSelect2(citySelect)) {
                        const url = 'https://api.novaposhta.ua/v2.0/json/';
                        fetch(url, {
                            method: 'POST',
                            body: JSON.stringify({
                                modelName: 'AddressGeneral',
                                calledMethod: 'getWarehouses',
                                methodProperties: {
                                    Language: `${this.localeValue}`,
                                    CityRef: value
                                },
                                apiKey: '1c73d14bea7e3b849b7bc0f1e5438d5a'
                            }),
                        })
                            .then(response => response.json())
                            .then(json => {
                                addressSelect.clearOptions()
                                addressSelect.addOption(json.data.map(datum => {
                                    return {
                                        value: datum[`CityDescription${locale === 'Ru' ? 'Ru' : ''}`] + ', ' + datum[`Description${locale === 'Ru' ? 'Ru' : ''}`],
                                        label: datum[`CityDescription${locale === 'Ru' ? 'Ru' : ''}`] + ', ' + datum[`Description${locale === 'Ru' ? 'Ru' : ''}`]
                                    }
                                }))
                                addressSelect.refreshOptions(false)
                                addressSelect.enable()
                            }).catch(() => {
                        });
                    }
                },
                load: (query, callback) => {
                    const url = 'https://api.novaposhta.ua/v2.0/json/';
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify({
                            modelName: 'Address',
                            calledMethod: 'searchSettlements',
                            methodProperties: {
                                Language: this.localeValue,
                                CityName: query,
                                Limit: 25
                            },
                            apiKey: '1c73d14bea7e3b849b7bc0f1e5438d5a'
                        }),
                    })
                        .then(response => response.json())
                        .then(json => {
                            callback(json.data[0]['Addresses']);
                        }).catch(() => {
                            callback();
                        });
                }
            })
        } else if (value === 'ukrposhta') {
            appendAfter(this.ukrPoshtaTemplateTarget.content.cloneNode(true), this.shippingMethodContainerTarget)
        } else if (value === 'meest_express') {
            appendAfter(this.meestExpressTemplateTarget.content.cloneNode(true), this.shippingMethodContainerTarget)

            let locale = this.localeValue.charAt(0).toUpperCase() + this.localeValue.slice(1)
            addressSelect = new TomSelect(this.addressTarget, {
                plugins: ['dropdown_input'],
                valueField: 'value',
                labelField: 'label',
                searchField: 'label',
                render: getTomSelectRender('label', this.localeValue),
                onChange: () => {
                    this.requiredSelect2(addressSelect)
                }
            })
            addressSelect.disable()

            citySelect = new TomSelect(this.cityTarget, {
                plugins: ['dropdown_input'],
                valueField: 'value',
                labelField: 'label',
                searchField: 'label',
                render: getTomSelectRender('label', this.localeValue),
                onChange: (value) => {
                    if (this.requiredSelect2(citySelect)) {
                        const url = `https://publicapi.meest.com/branches?city=${value}`;
                        fetch(url, {
                            method: 'GET',
                        })
                            .then(response => response.json())
                            .then(json => {
                                addressSelect.clearOptions()
                                addressSelect.addOption(json.result.map(res => {
                                    return {
                                        value: citySelect.getOption(citySelect.getValue()).innerText +
                                            `, ${locale === 'Ru' ? 'Отделение' : 'Відділення'} №${res['num_showcase']}`,
                                        label: `№${res['num_showcase']}`
                                    }
                                }))
                                addressSelect.refreshOptions(false)
                                addressSelect.enable()
                            }).catch(() => {
                        });
                    }
                },
                load: (query, callback) => {
                    const url = `https://publicapi.meest.com/geo_localities?search_beginning=${query}`;
                    fetch(url, {
                        method: 'GET'
                    })
                        .then(response => response.json())
                        .then(json => {
                            callback(json.result.map(res => {
                                let data = res.data
                                return {
                                    label: `${data['t_ua']} ${data[`${locale === 'Ru' ? 'n_ru' : 'n_ua'}`]}, ${data['reg']} обл., ${data['dis']} район`,
                                    value: data['city_id']
                                }
                            }));
                        }).catch(() => {
                            callback();
                    });
                }
            })
        }
    }

    nextStep(event) {
        const to = parseInt(event.currentTarget.getAttribute('data-step-to'))
        if (to === 2) {
            let invalid = [
                this.required(this.firstNameTarget), this.required(this.lastNameTarget),
                this.validEmail(), this.validPhoneNumber()
            ].includes(false)

            if (!invalid) {
                stepper.next()
            }
        } else if (to === 3) {
            let invalid;
            if (this.shippingMethodTarget.value === 'nova_poshta' || this.shippingMethodTarget.value === 'meest_express') {
                invalid = [
                    this.requiredSelect2(citySelect), this.requiredSelect2(addressSelect),
                ].includes(false)
            } else if (this.shippingMethodTarget.value === 'ukrposhta') {
                invalid = [
                    this.required(this.addressTarget), this.validZipcode(this.zipcodeTarget),
                ].includes(false)
            } else {
                invalid = false
            }

            if (!invalid) {
                this.setPaymentMethod()
                stepper.next()
            }
        } else if (to === 4) {
            this.populateSummary()
            this.submitButtonTarget.disabled = false
            stepper.next()
        }
    }

    prevStep = () => stepper.previous()

    populateSummary() {
        this.summaryFullNameTarget.innerHTML = `${this.firstNameTarget.value} ${this.lastNameTarget.value}`
        this.summaryEmailTarget.innerHTML = this.emailTarget.value
        this.summaryPhoneNumberTarget.innerHTML = this.phoneNumberTarget.value
        this.summaryShippingMethodTarget.innerHTML = this.shippingMethodTarget.options[this.shippingMethodTarget.selectedIndex].text

        let locale = this.localeValue.charAt(0).toUpperCase() + this.localeValue.slice(1)
        let address = ''
        if (this.shippingMethodTarget.value === 'nova_poshta') {
            address = addressSelect.getOption(addressSelect.getValue()).innerText
        } else if (this.shippingMethodTarget.value === 'meest_express') {
            address = citySelect.getOption(citySelect.getValue()).innerText
            address += `, ${locale === 'Ru' ? 'Отделение' : 'Відділення'} ${addressSelect.getOption(addressSelect.getValue()).innerText}`
        } else if (this.shippingMethodTarget.value === 'ukrposhta') {
            address = `${this.addressTarget.value}, ${this.zipcodeTarget.value}`
        } else {
            address = this.summaryShippingMethodTarget.innerHTML
        }
        this.summaryAddressTarget.innerHTML = address
        this.summaryPaymentMethodTarget.innerHTML = this.paymentMethodTarget.options[this.paymentMethodTarget.selectedIndex].text
    }

    required(element) {
        if (element.value.trim() === "") {
            appendError(element, i18nMessages[this.localeValue].required)
            return false
        } else {
            removeError(element)
            return true
        }
    }

    validEmail() {
        if (emailRegexp.test(this.emailTarget.value.trim())) {
            removeError(this.emailTarget)
            return true
        } else {
            appendError(this.emailTarget, i18nMessages[this.localeValue].incorrectFormat)
            return false
        }
    }

    validZipcode() {
        if (zipcodeRegexp.test(this.zipcodeTarget.value)) {
            removeError(this.zipcodeTarget)
            return true
        } else {
            appendError(this.zipcodeTarget, i18nMessages[this.localeValue].zipcode)
            return false
        }
    }

    validPhoneNumber() {
        if (phoneNumberRegexp.test(phoneNumberMask.unmaskedValue)) {
            removeError(this.phoneNumberTarget)
            return true
        } else {
            appendError(this.phoneNumberTarget, i18nMessages[this.localeValue].required)
            return false
        }
    }

    requiredSelect2 = (element) => {
        if (element.getValue() !== "") {
            removeError(element.wrapper)
            return true
        } else {
            appendError(element.wrapper, i18nMessages[this.localeValue].required)
            return false
        }
    }

    validateRequired = (event) => this.required(event.currentTarget)

    validateEmail = () => this.validEmail()

    validateZipcode = () => this.validZipcode()

    setPaymentMethod() {
        let valueToSet = i18nMessages[this.localeValue].cash
        if (this.shippingMethodTarget.value === 'nova_poshta' || this.shippingMethodTarget.value === 'ukrposhta' ||
            this.shippingMethodTarget.value === 'meest_express') {
            valueToSet = i18nMessages[this.localeValue].later_cash
        }
        this.paymentMethodTarget.options.forEach(o => {
            if (o.value === 'cash') {
                o.text = valueToSet
            }
        })
    }

}
