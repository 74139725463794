import { Controller } from "stimulus"
import Toastify from 'toastify-js'

export default class extends Controller {
    static targets = ['flash']

    connect() {
        console.log(this.flashTargets)
        this.flashTargets.forEach(flash => {
            Toastify({
                text: flash.innerText,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                className: `flash-${flash.getAttribute('data-notification-type')}`
            }).showToast();
        })
    }


}