import { Controller } from "stimulus"

const getHexColor = function (color) {
    var hexValues, rgbValues;
    if (!color) {
        return "";
    }
    if (/^#/.test(color)) {
        return color;
    }
    rgbValues = getRGBValues(color);
    hexValues = rgbValues.map(numberToHex);
    return "#" + hexValues.join("");
};

const numberToHex =  (number) => ("0" + (number.toString(16))).slice(-2).toUpperCase()

const getRGBValues = function (color) {
    var b, canvas, context, data, g, i, match, r;
    if (match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i)) {
        i = match.length - 3, r = match[i++], g = match[i++], b = match[i++];
    } else {
        canvas = document.createElement("canvas");
        canvas.height = canvas.width = 1;
        context = canvas.getContext("2d");
        context.fillStyle = color;
        context.fillRect(0, 0, 1, 1);
        data = context.getImageData(0, 0, 1, 1).data;
        r = data[0], g = data[1], b = data[2];
    }
    return [r, g, b].map(Number);
};

const createColorParser = function () {
    return function (element) {
        var color, styleColor;
        while (element && element.tagName !== "TRIX-EDITOR") {
            if (styleColor = element.style[this.styleProperty]) {
                if (color = getHexColor(styleColor)) {
                    return color;
                }
            }
            element = element.parentElement;
        }
    };
};

const Trix = require('trix')
require("@rails/actiontext")

Trix.config.textAttributes.foregroundColor = {
    inheritable: true,
    styleProperty: "color",
    parser: createColorParser()
}

export default class extends Controller {
    static targets = ['submitFormButton', 'editor', 'color']

    connect() {
        const buttonHTML = `<input type="color" class="trix-button trix-button--icon trix-button--icon-color"
                                   data-trix-attribute="foregroundColor" data-article-editor-target="color"
                                   data-action="input->article-editor#setColor click->article-editor#showPicker" />`
        const saveButtonHTML = `
                <span class="trix-button-group">
                    <button class="trix-button trix-button--icon trix-button--icon-save" title="Save"
                            data-action="click->article-editor#save"></a>
                </span>`

        this.editorTarget.toolbarElement
            .querySelector(".trix-button-group--text-tools")
            .insertAdjacentHTML("beforeend", buttonHTML);

        this.editorTarget.toolbarElement
            .querySelector(".trix-button-group--history-tools")
            .insertAdjacentHTML("afterend", saveButtonHTML);

        this.editorTarget.addEventListener("trix-selection-change", (event) => {
            this.colorTarget.value = event.target.editor.composition.currentAttributes.foregroundColor
        });

        this.editorTarget.addEventListener("trix-focus", (event) => {
            this.editorTarget.editor.activateAttribute('foregroundColor', this.colorTarget.value);
        });
    }

    clearEditorState = () => localStorage["editorState"] = ""

    save = () => this.submitFormButtonTarget.click()

    setColor = () => {
        this.editorTarget.editor.activateAttribute('foregroundColor', this.colorTarget.value)
        this.editorTarget.focus();
    }

    showPicker = (event) => {
        event.stopPropagation()
        event.stopImmediatePropagation()
    }

}