import { Controller } from "stimulus"

const toggleBackdrop = () => document.querySelector('#backdrop').classList.toggle('show')

export default class extends Controller {
    static targets = ['shoppingCart']

    showBackdrop() {
        toggleBackdrop()
    }

    requestDeleteConfirmation(event) {
        const locale = document.querySelector('html').getAttribute('lang')
        const message = locale === 'ru' ? 'Удалить из корзины?' : 'Видалити з кошика?';
        if (confirm(message)) {
            toggleBackdrop()
        } else {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }

    show = () => this.shoppingCartTarget.classList.add('active')

    hide = () => this.shoppingCartTarget.classList.remove('active')

    toggle = () => this.shoppingCartTarget.classList.toggle('active')

}